@import 'theme/variable' ;

$primary: #4050b5;
$secondary: #ff3f81;
$dark: #4050b5;
$light:#5064e1;
@import 'theme/style';
@import 'theme/responsive';
@import 'theme/customize';
@import 'theme/theme-dark';
@import 'theme/theme-rtl';